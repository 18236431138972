<template>
  <b-card body-class="p-0">
    <b-card-header class="p-1 main-header dark-bg">
      <a
        href="/#/cms/metroom"
        style="color: #5e5873"
        target="_parent"
        tag="h4"
        v-if="this.title === 'CMS 최근 종료된 미팅'"
        class="card-title cms-title"
        >{{ this.title }}</a
      >
      <a
        href="/#/cms/calls"
        style="color: #5e5873"
        target="_parent"
        tag="h4"
        v-if="this.title === 'CMS 진행중인 미팅'"
        class="card-title cms-title"
        >{{ this.title }}</a
      >
      <a
        href="/#/cms/resv"
        style="color: #5e5873"
        target="_parent"
        tag="h4"
        v-if="this.title === 'CMS 예약'"
        class="card-title cms-title"
        >{{ this.title }}</a
      >
    </b-card-header>
    <!-- 리스트 5개 -->
    <b-list-group
      class="list-group-flush"
      v-if="sstusList.length > 0"
      style="min-height: 159px"
    >
      <b-list-group-item
        v-for="(list, index) in sstusList.slice(0, 5)"
        :key="index"
        class="cms-main-list"
      >
        <span v-if="list.callGuid">
          <!-- <small class="text-secondary text-lighten-4">2021-02-06 12:00</small> -->
          {{ list.name }}
        </span>
        <span v-else-if="list.callEndTime">
          <small class="text-secondary text-lighten-4">{{
            list.callEndTime
          }}</small>
          {{ list.callName }}
        </span>
        <span v-else-if="list.title">
          <!-- <small class="text-secondary text-lighten-4">{{ list.callEndTime }}</small>
                  {{ list.callName }} -->
        </span>
        <span v-if="list.resvSeq">
          <small class="text-secondary text-lighten-4">{{
            list.startDt
          }}</small>
          {{ list.title }}
        </span>
      </b-list-group-item>
    </b-list-group>
    <!-- 리스트 없음 -->
    <b-list-group class="list-group-flush" v-else style="min-height: 159px">
      <b-list-group-item
        v-if="cmsLoading === true"
        class="cms-main-list pl-1 p-10px"
      >
        <small class="text-secondary text-lighten-4"></small>
        <b-skeleton animation="wave" width="100%"></b-skeleton>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="60%"></b-skeleton>
        <b-skeleton animation="wave" width="93%"></b-skeleton>
        <b-skeleton animation="wave" width="45%"></b-skeleton>
      </b-list-group-item>
      <b-list-group-item v-else class="cms-main-list pl-1 p-10px">
        <small class="text-secondary text-lighten-4"></small>
        조회된 결과가 없습니다.
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BSkeleton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BSkeleton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    sstusList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    cmsLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    // console.log("*****리스트", this.sstusList)
    // console.log(this.$store.state.reserveList)
  },
  mounted() {
    // console.log('TEST 1 ', this.sstusList)
  },
  watch: {},
};
</script>