<template>
  <b-card style="min-height : 262px;">
    <b-row>
      <!-- VCM 도넛 차트 -->
      <!-- Fault Chart -->
      <b-col xl="4" lg="4" md="12">
        <router-link to="/vcm/codecsttus">
          <div style="position:relative">
            <div class="text-center" style="margin-left: 10px; color: #5e5873; font-weight: 600; margin-bottom: 10px;">Fault</div>
            <div class="chartLabel" v-if="$store.state.mainCodecList.fault">
              <span>{{ $store.state.mainCodecList.fault.cnt }}/{{ $store.state.mainCodecList.total }}</span>
            </div>
            <div class="chart-wrapper">
              <apexchart class="tooltip-none" type="donut" width="250" :options="chartOptions" :series="series1" style="margin: 0 auto;"></apexchart>
            </div>
          </div>
        </router-link>
      </b-col>
      <!-- Fault Chart END -->
      <!-- Usage Chart -->
      <b-col xl="4" lg="4" md="12" class="test">
        <router-link to="/vcm/codecsttus">
          <div style="position:relative">
            <div class="text-center" style="margin-left: 10px; color: #5e5873; font-weight: 600; margin-bottom: 10px;">Usage</div>
            <div class="chartLabel" v-if="$store.state.mainCodecList.call">
              <span>{{ $store.state.mainCodecList.call.cnt }}/{{ $store.state.mainCodecList.total }}</span>
            </div>
            <apexchart class="tooltip-none" type="donut" width="250"  :options="chartOptions2" :series="series2" style="margin: 0 auto;"></apexchart>
          </div>
        </router-link>
      </b-col>
      <!-- Usage Chart END -->
      <!-- Activation Chart -->
      <b-col xl="4" lg="4" md="12">
        <router-link to="/vcm/codecsttus">
          <div style="position:relative">
            <div class="text-center" style="margin-left: 10px; color: #5e5873; font-weight: 600; margin-bottom: 10px;">On/Off</div>
            <div class="chartLabel" v-if="$store.state.mainCodecList.onoff">
              <span>{{ $store.state.mainCodecList.onoff.cnt }}/{{ $store.state.mainCodecList.total }}</span>
            </div>
            <apexchart class="tooltip-none" type="donut" width="250"  :options="chartOptions3" :series="series3" style="margin: 0 auto;"></apexchart>
          </div>
        </router-link>
      </b-col>
      <!-- Activation Chart END -->
      <!-- VCM 도넛 차트 END -->
    </b-row>
  </b-card>
</template>
<style>
.tooltip-none .apexcharts-tooltip {
  /* display:none!important; */
}

.chartLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3px 0 0 -32px;
  min-width: 80px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.apexcharts-canvas.apexcharts-theme-light{
  margin: 0 auto !important;
}

div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>
<script>
import { BCard, BCol, BRow } from "bootstrap-vue"
import VcmChart from "./components/VcmChart.vue"
import { $themeColors } from "@themeConfig"
// import VcmCommon from './VcmCommon'

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
}

export default {
  components: {
    VcmChart,
    BCard,
    BCol,
    BRow,
  },
  data() {
    return {
      series1: [0,1533],
      series2: [0,1533],
      series3: [0,1533],
      chartOptions: { // vcm
        labels: ['발생', '미발생'],
        chart: {
          width: 250,
          type: "donut",
        },
        options: {
          colors: ['#ff4961', '#299AFF']
        },
        color: ['#ff4961', '#299AFF'],
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#ff4961', '#299AFF']
          },
        },
        fill: {
          colors: ['#ff4961', '#299AFF']
        },
        markers: {
          colors: ['#ff4961', '#299AFF']
        },
        tooltip:{
          style: {
            colors: ['#ff4961', '#299AFF']
          },
        },
        responsive: [
          {
            breakpoint: 250,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          // position: "right",
          offsetY: 0,
          // height: 230,
          show: false,
        },
      },
      chartOptions2: { // usage
        labels: ['off', 'on'],
        chart: {
          width: 250,
          type: "donut",
        },
        options: {
          colors: ['#ff4961', '#299AFF']
        },
        color: ['#ff4961', '#299AFF'],
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#ff4961', '#299AFF']
          },
        },
        fill: {
          colors: ['#ff4961', '#299AFF']
        },
        markers: {
          colors: ['#ff4961', '#299AFF']
        },
        tooltip:{
          style: {
            colors: ['#ff4961', '#299AFF']
          },
        },
        responsive: [
          {
            breakpoint: 250,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          // position: "right",
          offsetY: 0,
          // height: 230,
          show: false,
        },
      },
      chartOptions3: { // on-off
        labels: ['발생','미발생'],
        chart: {
          width: 250,
          type: "donut",
        },
        options: {
          colors: ['#ff4961', '#299AFF']
        },
        color: ['#ff4961', '#299AFF'],
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#ff4961', '#299AFF']
          },
        },
        fill: {
          colors: ['#ff4961', '#299AFF']
        },
        markers: {
          colors: ['#ff4961', '#299AFF']
        },
        tooltip:{
          style: {
            colors: ['#ff4961', '#299AFF']
          },
        },
        responsive: [
          {
            breakpoint: 250,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          // position: "right",
          offsetY: 0,
          // height: 230,
          show: false,
        },
      },
      message: null,
      payload: {
        fault: {
          cnt: 0,
        },
        call: {
          cnt: 0,
        },
        onoff: {
          cnt: 0,
        },
        total: 0,
      },
      doughnutChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          title: {
            display: true,
            text: "Fault",
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ""
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} `
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ["have", "not"],
              data: [0, 1533],
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
              ],
              borderWidth: 1,
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            },
          ],
        },
      },
      doughnutChart2: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          title: {
            display: true,
            text: "Usege",
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ""
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} `
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ["have", "not"],
              data: [0, 1533],
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
              ],
              borderWidth: 1,
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            },
          ],
        },
      },
      doughnutChart3: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          title: {
            display: true,
            text: "On/Off",
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ""
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} `
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ["have", "not"],
              data: [0, 1533],
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
              ],
              borderWidth: 1,
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            },
          ],
        },
      },
    }
  },
  watch: {
    '$store.state.mainCodecList': function(val){
      this.setData(val)
    }
  },
  created() {
    this.$store.state.mainCodecList = { // 페이지 created시 데이터 구조 생성
      fault: {
        cnt: 0
      },
      call: {
        cnt: 0
      },
      onoff: {
        cnt: 0
      },
      total: 0
    }
  },
  mounted() {
    this.vcmWsConect()
    this.setData(this.$store.state.mainCodecList)
  },
  methods: {
    /**
     * 메인 코덱 차트 data set 
     * @create 2021-04-26
     * @author 정한슬
     * @description 메인 코덱 차트 data set 
    */
    setData(val) {
      if(val.fault){
        if(val.fault.cnt){
          this.series1[0] = val.fault.cnt // fault
          this.series1[1] = val.total // total
        }
      }
      
      if(val.call){
        if(val.call.cnt){
          this.series2[0] = val.call.cnt // acti
          this.series2[1] = val.total // total
        }
      }
      if(val.onoff){
        if(val.onoff.cnt){
          this.series3[0] = val.onoff.cnt // on/off
          this.series3[1] = val.total // total
        }
      }
      
      window.dispatchEvent(new Event('resize'))
    },
    
    vcmWsConect(){
      const _self = this
      this.$options.sockets = new WebSocket(`${this.wsurl}`)
      // console.log(this.ucssocket)
      this.$options.sockets.onopen = function(e) {
        // this.cmsLoading = false;
        // console.log(`[커넥션]`)
      };
      this.$options.sockets.onmessage = (evt) => {
        let payload = JSON.parse(evt.data)
        if(payload.data){
          if(payload.data.payload){
            this.$store.commit('updateMainCodecList', payload.data.payload)
          }
        }
      }
      this.$options.sockets.onclose = function(event) {
        this.cmsLoading = true;
        setTimeout(function() {
          _self.vcmWsConect();
        }, 1000);
      };
      this.$options.sockets.onerror = function(err) {
          this.$options.sockets.close();
      };
    },
    appendData: function () {
      var arr = this.series.slice()
      arr.push(Math.floor(Math.random() * (100 - 1 + 1)) + 1)
      this.series = arr
    },

    removeData: function () {
      if (this.series.length === 1) return
      var arr = this.series.slice()
      arr.pop()
      this.series = arr
    },

    randomize: function () {
      this.series = this.series.map(function () {
        return Math.floor(Math.random() * (100 - 1 + 1)) + 1
      })
    },

    reset: function () {
      this.series = [44, 55, 13, 33]
    },
  },
}
</script>
