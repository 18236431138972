<script>
import { Doughnut } from 'vue-chartjs'
export default {
  extends: Doughnut,
  props: {
    Chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.addPlugin({
      beforeDraw(c) {
        const ctx = [c.chart.ctx]
        ctx.fillStyle = '#777'
        ctx.fillRect = [0, 0, c.chart.width, c.chart.height]
      },
    })
    this.renderChart(this.Chartdata, this.options)
  },
  methods: {
  },
}
</script>
