<template>
    <div class="log-l-main">
        <app-timeline v-if="data.length == 0 && ucsLoading === true" class="ml-0 p-1">
            <b-skeleton animation="wave" width="100%"></b-skeleton>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="45%"></b-skeleton>
            <b-skeleton animation="wave" width="93%"></b-skeleton>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
        </app-timeline>
        <app-timeline v-if="data.length > 0 && ucsLoading === true" class="ml-0 p-1">
            <b-skeleton animation="wave" width="100%"></b-skeleton>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
        </app-timeline>
        <app-timeline style="" v-if="data.length > 0">
                <app-timeline-item 
                v-for="list in data"
                :key="list.index"
                variant="primary" 
                :class="list.severity"
                >
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6>{{list.severity}} ( {{list.id}} )</h6>
                    </div>
                    <div class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0">
                        <small class="text-muted">등록시점 : {{list.serverTm}}</small>
                        <small class="text-muted" style="padding:0 4px">/</small>
                        <small class="text-muted">발생시점 : {{list.created}}</small>
                    </div>
                    <p class="mb-0">{{list.descr}}</p>
                    <div class="d-flex flex-column justify-content-between align-items-end">
                        <span class="font-weight-bolder">{{list.name}} / {{list.ip}} </span>
                    </div>
                </app-timeline-item>
        </app-timeline>
        <div v-else-if="data.length == 0 && ucsLoading === false" class="pl-1">
            조회된 결과가 없습니다.
        </div>
    </div>
</template>
<style>
/* severity INFO */
.timeline-item.timeline-variant-primary.info .timeline-item-point{
    background-color: #82868b!important;
}
.timeline-item.timeline-variant-primary.info .timeline-item-point::before{
    background-color: rgba(130,134,139,.12);
}
/* severity CRITICAL */
.timeline-item.timeline-variant-primary.critical .timeline-item-point{
    background-color: #ea5455!important;
}
.timeline-item.timeline-variant-primary.critical .timeline-item-point::before{
    background-color: rgba(255, 78, 78, 0.12);
}
/* severity MAJOR */
.timeline-item.timeline-variant-primary.major .timeline-item-point{
    background-color: #ff9f43!important;
}
.timeline-item.timeline-variant-primary.major .timeline-item-point::before{
    background-color: rgba(255, 123, 0, 0.12);
}
/* severity MINOR */
.timeline-item.timeline-variant-primary.minor .timeline-item-point{
    background-color: #ffd500!important;
}
.timeline-item.timeline-variant-primary.minor .timeline-item-point::before{
    background-color: rgba(255, 217, 0, 0.12);
}
/* severity WARNING */
.timeline-item.timeline-variant-primary.warning .timeline-item-point{
    background-color: #00967e!important;
}
.timeline-item.timeline-variant-primary.warning .timeline-item-point::before{
    background-color: rgba(30, 119, 100, 0.12);
}
</style>
<script>
import {
  BRow,
  BSkeleton
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
    components: {
        // BRow,
        AppTimeline,
        AppTimelineItem,
        BSkeleton,
    },
    props: {
        ucsLoading: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            data: [],
        }
    },
    computed: {
        returnAllEvtList() {
            return this.$store.state.ucsList.allEventList
        },
    },
    watch: {
        returnAllEvtList(val) {
            // console.log(val)
            this.data = []
            this.data = val
        },
    },
    mounted() {
        // console.log(this.ucsLoading)
        this.data = []
        this.data = this.$store.state.ucsList.allEventList
        // console.log(this.data)
    },
    methods: {
        deepCopy(obj){
            var copy;

            // Handle the 3 simple types, and null or undefined
            if (null == obj || "object" != typeof obj) return obj;

            // Handle Date
            if (obj instanceof Date) {
                copy = new Date();
                copy.setTime(obj.getTime());
                return copy;
            }

            // Handle Array
            if (obj instanceof Array) {
                copy = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    copy[i] = this.deepCopy(obj[i]);
                }
                return copy;
            }

            // Handle Object
            if (obj instanceof Object) {
                copy = {};
                for (var attr in obj) {
                    if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
                }
                return copy;
            }
            throw new Error("Unable to copy obj! Its type isn't supported.");
        },
    },    
}
</script>
